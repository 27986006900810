import React from 'react'
import Title from '../Title/Title'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

export default ({ title, description, testomonial, image }) => {
  return (
    <div className="tabular-content has-background-light">
      <div className="columns has-padding-5">
        <div className="column is-one-third-widescreen is-half tabular-left">
          <Title>{title}</Title>
          <p>{description}</p>
          <AnchorLink to="/#contactus">
            <button className="button is-success is-large has-margin-top-4 has-margin-bottom-6 has-padding-left-5 has-padding-right-5">
              <span className="is-size-6 has-text-weight-bold">GET IN TOUCH</span>
            </button>
          </AnchorLink>

          <div className="tabular-testomonial">
            <p className="lighten has-text-weight-semi-bold has-margin-bottom-3">
              "{testomonial.text}"
            </p>
            <div>
              <h5 className="title is-5 has-text-success is-marginless has-text-weight-bold has-padding-top-1">
                {testomonial.name}
              </h5>
              <p className="has-text-weight-light">{testomonial.title}</p>
              <div className="quote-mug-container">
                <Img fluid={testomonial.mug} alt="Person" className="quote-mug" />
              </div>
            </div>
          </div>
        </div>
        <div className="column container">
          <img src={image} alt="Illustration" className="tabular-illustration" />
        </div>
      </div>
    </div>
  )
}
