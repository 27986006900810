import React, { useEffect } from 'react'
import '../styles/main.scss'
import '../styles/index.scss'
import { graphql } from 'gatsby'

import Hero from '../components/Hero/Hero'
import Title from '../components/Title/Title'
import CompanyList from '../components/CompanyList/CompanyList'
import Tabular from '../components/Tabular/Tabular'
import FeaturedCard from '../components/FeaturedCard/FeaturedCard'
import Why from '../components/Why/Why'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout/Layout'

export default ({ data }) => {
  /*
    Page Layout:
    Sections are background blocks.
    Div's inside are containers for elements inside of the background.
  */

  // Initialize Hubspot Form
  useEffect(() => {
    const handleHubspotScript = () => {
      // Instantly remove event listener
      window.removeEventListener('scroll', handleHubspotScript)

      // Create hubspot script
      setTimeout(() => {
        const script = document.createElement('script')
        script.src = 'https://js.hsforms.net/forms/v2.js'
        script.defer = true
        document.body.appendChild(script)

        // Wait for script to load, then add form
        script.addEventListener('load', () => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              portalId: '5666503',
              formId: '058bd793-908b-48e9-80ce-868cdc8c980f',
              target: '#hubspot_form',
            })
          }
        })
      }, 1000)
    }

    // Add event listener
    window.addEventListener('scroll', handleHubspotScript)

    return () => {
      window.removeEventListener('scroll', handleHubspotScript)
    }
  }, [])

  return (
    <Layout>
      <main>
        <Helmet>
          <meta property="og:image" content="/cp-website-min.jpg" />
          <script type="text/javascript">{'function initApollo(){var n=Math.random().toString(36).substring(7),o=document.createElement("script"); o.src="https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache="+n,o.async=!0,o.defer=!0, o.onload=function(){window.trackingFunctions.onLoad({appId:"66294cedd50c9601c741a4d8"})}, document.head.appendChild(o)}initApollo();'}</script>
        </Helmet>
        {/* Wrap this with the Layout component when it's created */}
        <Hero />
        <CompanyList />
        <section id="solutions" className="has-padding-top-5 products-why-container">
          <Tabular
            norwest_black={data.norwest_black}
            kf_black={data.kf_black}
            gsv_black={data.gsv_black}
          />

          <Why />
        </section>
        <section
          id="research"
          className="has-background-primary has-padding-bottom-5 has-padding-top-5 featured-container"
        >
          <Title className="has-text-centered has-text-white has-padding-top-5 has-padding-bottom-5 is-size-1-desktop">
            Featured Research Projects
          </Title>
          <FeaturedCard posts={data.allWordpressPost.nodes} />
        </section>
        <section className="has-padding-top-6 has-padding-bottom-6 clients-cta-container">
          <div className="container has-margin-top-5 testomonials">
            <div className="columns">
              <div className="column is-one-quarter has-padding-right-5">
                <Title className="is-size-1">What our clients say.</Title>
                <p>
                  College Pulse partners with leading academic researchers, prominent think tanks,
                  and global brands to conduct ground-breaking research on the college demographic.
                  Here’s what they’re saying about us:
                </p>
              </div>
              <div className="column">
                <div className="columns has-text-white is-multiline">
                  <div className="column is-half  is-flex">
                    <div className="has-text-centered box is-between is-flex is-flex-column is-centered has-background-info has-text-white is-radiusless has-padding-5">
                      <Img
                        style={{ width: '35%' }}
                        fluid={data.chegg.childImageSharp.fluid}
                        alt="Company Logo"
                      />
                      <p className="has-padding-top-4">
                        "We were incredibly fortunate to work with College Pulse on the
                        Chegg/College Pulse Election Tracker. The project was very ambitious, but
                        they not only were able to deliver on a large scale, they exceeded my
                        expectations on everything from framing the research questions to
                        methodological rigor to presentation of insights. They were very easy to
                        work with and a truly turn-key solution -- more importantly, their extra
                        efforts helped us exceed our business goals for the project.”
                      </p>
                      <div>
                        <h4 className="subtitle is-marginless has-padding-top-4 has-text-white has-text-weight-bold">
                          Bonny Brown
                        </h4>
                        <p>Head of Research, Chegg</p>
                      </div>
                    </div>
                  </div>
                  <div className="column is-half  is-flex">
                    <div className="has-text-centered box is-between is-flex is-flex-column is-centered has-background-info has-text-white is-radiusless has-padding-5">
                      <Img
                        style={{ width: '35%' }}
                        fluid={data.nasce.childImageSharp.fluid}
                        alt="Company Logo"
                      />
                      <p className="has-padding-top-4">
                        “College Pulse ignited our study of civic engagement and global citizenry.
                        After conducting our study one college at a time, College Pulse’s robust
                        panel allowed us to understand all American college students commitment to
                        serve and development pursuant to most college missions quickly and with the
                        highest methodological standards.”
                      </p>
                      <div>
                        <h4 className="subtitle is-marginless has-padding-top-4 has-text-white has-text-weight-bold">
                          Don Levy, Ph.D
                        </h4>
                        <p>
                          NASCE, Director Siena College Research Institute and Principal
                          Investigator
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            id="contactus"
            className="container has-padding-top-6 has-margin-top-6 cta-form has-padding-bottom-6"
          >
            <Title className="has-text-weight-bold has-text-centered is-size-1-desktop">
              Ready to get started?
            </Title>

            <hr
              className="has-background-success has-margin-4 has-margin-top-3 opacity03"
              style={{ width: '50%', marginLeft: 'auto', marginRight: 'auto' }}
            />

            <div id="hubspot_form" />
          </div>
        </section>
      </main>
    </Layout>
  )
}

export const homeQuery = graphql`
  query {
    norwest: file(relativePath: { eq: "testomonials/norwest.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kf: file(relativePath: { eq: "testomonials/kf.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gsv: file(relativePath: { eq: "testomonials/gsv.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    chegg: file(relativePath: { eq: "testomonials/chegg.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    nasce: file(relativePath: { eq: "testomonials/nasce.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    norwest_black: file(relativePath: { eq: "testomonials/norwest_black.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kf_black: file(relativePath: { eq: "testomonials/kf_black.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    gsv_black: file(relativePath: { eq: "testomonials/gsv_black.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    dartmouth: file(relativePath: { eq: "testomonials/dartmouth.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    allWordpressPost(
      sort: { fields: [date], order: DESC }
      filter: { categories: { elemMatch: { slug: { eq: "featured" } } } }
    ) {
      nodes {
        slug
        title
        excerpt
        link
        date
        featured_media {
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        acf {
          featured_in_1 {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          featured_in_2 {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          featured_in_3 {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          featured_in_4 {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          featured_in_5 {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
