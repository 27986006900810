import React from 'react'
import Title from '../Title/Title'
import './Why.scss'

export default () => {
  return (
    <div className="why-container has-margin-top-6 container has-padding-top-6 has-padding-bottom-6 has-margin-bottom-6">
      <Title className="has-text-centered is-size-1-desktop has-margin-top-4">
        Why College Pulse?
      </Title>
      <div className="columns has-margin-top-5 is-variable is-9 has-margin-bottom-4">
        <div className="column content">
          <img className="why-icon" src="/icons/why_reach.svg" alt="Why College Pulse?" />
          <h4 className="is-marginless has-padding-top-3 has-text-weight-bold has-text-primary has-padding-bottom-2">
            Unparalleled Reach
          </h4>
          <p>
            Our American College Student Panel is the largest of it's kind with more than 800,000
            college student and recent grads from 1500 two and four-year colleges and universities
            in all 50 states.
          </p>
        </div>
        <div className="column content">
          <img className="why-icon" src="/icons/why_method.svg" alt="Why College Pulse?" />
          <h4 className="is-marginless has-padding-top-3 has-text-weight-bold has-text-primary has-padding-bottom-2">
            Rigorous Methodology
          </h4>
          <p>
            Crafted by industry veterans with extensive opinion polling backgrounds, our methodology
            ensures we present data that accurately reflects the attitudes and opinions of your
            target population.
          </p>
        </div>
        <div className="column content">
          <img className="why-icon" src="/icons/why_student.svg" alt="Why College Pulse?" />
          <h4 className="is-marginless has-padding-top-3 has-text-weight-bold has-text-primary has-padding-bottom-2">
            College Student Experts
          </h4>
          <p>
            No one understands college students better than us. We've collected more than 100
            million responses from students around the country.
          </p>
        </div>
      </div>
    </div>
  )
}
