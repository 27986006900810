import React, { useState } from 'react'
import './FeaturedCard.scss'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
const CondLink = ({ children, post }) => {
  if (post.link.includes('https://wordpress.collegepulse.com/2')) {
    return <Link to={`/blog/${post.slug}`}>{children}</Link>
  } else {
    return <a href={post.link}>{children}</a>
  }
}

const FeaturedCard = ({ posts }) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const [animating, setAnimating] = useState(false)
  const [reversed, setReversed] = useState(false)
  const filteredPosts = posts.filter(e => e.title !== 'dummy')

  const handleChangeSlide = direction => {
    setAnimating(true)
    setReversed(prevState => !prevState)
    setTimeout(() => {
      if (direction === 'left') {
        setActiveSlide(prevState => (prevState === 0 ? filteredPosts.length - 1 : prevState - 1))
      } else {
        setActiveSlide(prevState => (prevState === filteredPosts.length - 1 ? 0 : prevState + 1))
      }
      setAnimating(false)
    }, 100)
  }

  return (
    <React.Fragment>
      <div
        className={`featured-content-section container is-flex ${reversed ? 'reversed' : ''} ${
          animating ? 'animating' : ''
        }`}
      >
        <div className="featured-left-arrow arrows" onClick={() => handleChangeSlide('left')}>
          <img src="/icons/leftarr.svg" width="25" alt="Left Arrow" />
        </div>
        <div className="has-background-info has-padding-6 content featured-card-content-box">
          <h2
            className="title has-text-white has-text-weight-bold"
            dangerouslySetInnerHTML={{ __html: filteredPosts[activeSlide].title }}
          />
          <div
            className="has-text-light has-text-weight-light"
            dangerouslySetInnerHTML={{ __html: filteredPosts[activeSlide].excerpt }}
          />
          {filteredPosts[activeSlide].link.includes('https://wordpress.collegepulse.com/2') ? (
            <Link className="has-text-success" to={`/blog/${filteredPosts[activeSlide].slug}`}>
              READ THE REPORT
            </Link>
          ) : (
            <a
              target="_blank"
              className="has-text-success"
              href={filteredPosts[activeSlide].link}
              rel="noreferrer"
            >
              READ THE REPORT
            </a>
          )}

          {filteredPosts[activeSlide].acf['featured_in_1'] && (
            <div className="has-margin-top-5 is-100-width">
              <h4 className="has-text-white is-size-7 has-text-weight-bold">
                THIS RESEARCH WAS FEATURED IN:
              </h4>
              <div className="featured-in-container">
                {filteredPosts[activeSlide].acf['featured_in_1'] && (
                  <Img
                    fluid={
                      filteredPosts[activeSlide].acf['featured_in_1'].localFile.childImageSharp
                        .fluid
                    }
                    className={`has-margin-${reversed ? 'right' : 'left'}-2`}
                    alt="Featured In: Company Logo"
                  />
                )}
                {filteredPosts[activeSlide].acf['featured_in_2'] && (
                  <Img
                    fluid={
                      filteredPosts[activeSlide].acf['featured_in_2'].localFile.childImageSharp
                        .fluid
                    }
                    className={`has-margin-${reversed ? 'right' : 'left'}-2`}
                    alt="Featured In: Company Logo"
                  />
                )}
                {filteredPosts[activeSlide].acf['featured_in_3'] && (
                  <Img
                    fluid={
                      filteredPosts[activeSlide].acf['featured_in_3'].localFile.childImageSharp
                        .fluid
                    }
                    className={`has-margin-${reversed ? 'right' : 'left'}-2`}
                    alt="Featured In: Company Logo"
                  />
                )}
                {filteredPosts[activeSlide].acf['featured_in_4'] && (
                  <Img
                    fluid={
                      filteredPosts[activeSlide].acf['featured_in_4'].localFile.childImageSharp
                        .fluid
                    }
                    className={`has-margin-${reversed ? 'right' : 'left'}-2`}
                    alt="Featured In: Company Logo"
                  />
                )}
                {filteredPosts[activeSlide].acf['featured_in_5'] && (
                  <Img
                    fluid={
                      filteredPosts[activeSlide].acf['featured_in_5'].localFile.childImageSharp
                        .fluid
                    }
                    className={`has-margin-${reversed ? 'right' : 'left'}-2`}
                    alt="Featured In: Company Logo"
                  />
                )}
              </div>
            </div>
          )}
          <div className="dots">
            {filteredPosts.map((post, index) => (
              <div
                onClick={() => {
                  setReversed(!reversed)
                  setActiveSlide(index)
                }}
                className={`dot ${index === activeSlide ? 'active-dot' : ''}`}
              />
            ))}
          </div>
        </div>
        <div className="has-background-success has-padding-4 featured-content-preview">
          {filteredPosts[activeSlide]['featured_media'] && (
            <CondLink post={filteredPosts[activeSlide]}>
              <Img
                fluid={filteredPosts[activeSlide]['featured_media'].localFile.childImageSharp.fluid}
                alt="Featured Project"
                className={`featured-image ${animating ? 'animating' : ''}`}
              />
            </CondLink>
          )}
        </div>
        <div className="featured-right-arrow arrows" onClick={() => handleChangeSlide('right')}>
          <img src="/icons/rightarr.svg" width="25" alt="Right Arrow" />
        </div>
      </div>

      <div
        className="container has-margin-top-5"
        style={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        <div className="blog-box has-background-info has-text-white is-radiusless flex has-padding-5 has-padding-top-4 has-padding-bottom-4">
          <div className="columns">
            <div className="column">
              <h3 className="title is-4 has-text-white has-text-weight-bold is-marginless has-padding-bottom-2">
                News & Research
              </h3>

              <p>
                See all our latest research projects and how they’re leveraged to create impact.
              </p>
            </div>
            <Link
              to="/blog/page/1"
              className="has-text-success has-text-weight-bold column is-centered"
            >
              <span className="is-centered">
                See Our Latest Findings
                <img
                  className="has-margin-left-4"
                  src="/icons/rightarr.svg"
                  width="25"
                  alt="Right Arrow"
                />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default FeaturedCard
