import React from 'react'

import './CompanyList.scss'

export default () => {
  return (
    <section className="test-duplicate-used has-background-info has-padding-top-5 has-padding-bottom-5">
      <h5 className="has-text-centered has-text-white subtitle is-6">
        OUR RESEARCH IS TRUSTED BY:
      </h5>
      <div className="company-list columns has-text-white has-margin-top-4 has-text-centered is-flex has-padding-left-4 has-padding-right-4">
        <img
          className="has-margin-2"
          style={{ width: 138 }}
          src="/companies/nyt.svg"
          alt="Company Logo"
        />
        <img
          className="has-margin-2"
          style={{ width: 138 }}
          src="/companies/kf.svg"
          alt="Company Logo"
        />
        <img
          className="has-margin-2"
          style={{ width: 190 }}
          src="/companies/bnp.svg"
          alt="Company Logo"
        />
        <img
          className="has-margin-2"
          style={{ width: 70 }}
          src="/companies/dell.svg"
          alt="Company Logo"
        />
        <img
          className="has-margin-2"
          style={{ width: 130 }}
          src="/companies/forbes.svg"
          alt="Company Logo"
        />
        <img
          className="has-margin-2"
          style={{ width: 150 }}
          src="/companies/fivethirtyeight.svg"
          alt="Company Logo"
        />
        <img
          className="has-margin-2"
          style={{ width: 70 }}
          src="/companies/ge.svg"
          alt="Company Logo"
        />
        <img
          className="has-margin-2"
          style={{ width: 97 }}
          src="/companies/chegg.svg"
          alt="Company Logo"
        />
      </div>
    </section>
  )
}
