import React, { useState } from 'react'
import { graphql } from 'gatsby'

import './Tabular.scss'

import TabContent from './TabContent'

const Tabular = ({ kf_black, norwest_black, gsv_black }) => {
  const [activeSlide, setActiveSlide] = useState(0)

  const slideData = [
    {
      title: 'Custom Research',
      description:
        'Work with our team of survey experts to design custom research on the student demographic. Whether you need to interview computer science majors at MIT, students at online universities or a nationally representative sample, we’ve got you covered.',
      testomonial: {
        text: 'College Pulse is an outstanding research partner - highly responsive and collaborative from start to finish. We have findings in hand in a matter of days and an insightful, visually engaging report within weeks. Thank you for designing efficiency and quality into your offering.',
        name: 'Evette Alexander',
        title: 'Director of Learning & Impact, Knight Foundation',
        mug: kf_black.childImageSharp.fluid,
      },
      image: '/ill/research.svg',
    },
    {
      title: 'Brand Trackers',
      description:
        'Measure how student perception of your brand changes over time and compare to your competitors. We work with marketing, campus recruiting and communication teams to measure campaign impact, understand brand perception, and track their competition.',
      testomonial: {
        text: 'As consumer venture investors, we know the most valuable consumer trends begin with the college demographic. College Pulse insights are critical information for any private investor that needs to stay abreast of the leading brands and the quickly changing consumer perspectives.',
        name: 'Ed Yip',
        title: 'Partner, Norwest Venture Partners',
        mug: norwest_black.childImageSharp.fluid,
      },
      image: '/ill/tracker.svg',
    },
    {
      title: 'Syndicated Research',
      description:
        'Tap into College Pulse Insights™– our interactive statistical analysis platform where you can visualize and explore our database of over 100M responses to get the insights you need on topics ranging from best practices in campus recruiting, emerging consumer trends, and student life on campus.',
      testomonial: {
        text: 'Understanding college students should be at the forefront of every company in the ed space. College Pulse is the undeniable leader when it comes to college insights, and the right partner for any company looking to understand what college students really think.',
        name: 'Deborah Quazzo',
        title: 'Managing Partner, GSV AcceleraTE',
        mug: gsv_black.childImageSharp.fluid,
      },
      image: '/ill/reports.svg',
    },
  ]

  return (
    <section className="tabs-container has-margin-top-5 has-margin-bottom-5 container has-background-white">
      <div className="tabs is-toggle is-large is-marginless is-fullwidth">
        <ul>
          <li
            class={`tabular-tab ${activeSlide === 0 ? 'has-background-light has-text-white' : ''}`}
          >
            <a onClick={() => setActiveSlide(0)}>
              <span className="tabular-text-icon">
                <img src="/icons/tabs/research.svg" alt="Tab Icon" />
              </span>
              <span className="tabular-tab-text">Custom Research</span>
            </a>
          </li>
          <li
            class={`tabular-tab ${activeSlide === 1 ? 'has-background-light has-text-white' : ''}`}
          >
            <a onClick={() => setActiveSlide(1)}>
              <span className="tabular-text-icon">
                <img src="/icons/tabs/trackers.svg" alt="Tab Icon" />
              </span>
              <span className="tabular-tab-text">Brand Trackers</span>
            </a>
          </li>
          <li
            class={`tabular-tab ${activeSlide === 2 ? 'has-background-light has-text-white' : ''}`}
          >
            <a onClick={() => setActiveSlide(2)}>
              <span className="tabular-text-icon">
                <img src="/icons/tabs/reports.svg" alt="Tab Icon" />
              </span>
              <span className="tabular-tab-text">Syndicated Reports</span>
            </a>
          </li>
        </ul>
      </div>

      <TabContent
        title={slideData[activeSlide].title}
        description={slideData[activeSlide].description}
        testomonial={slideData[activeSlide].testomonial}
        image={slideData[activeSlide].image}
      />
    </section>
  )
}

export default Tabular
